<template>
  <div class="fixed inset-0 bg-black flex justify-center items-start transition-all duration-200 z-10"
    :class="isShow ? 'visible bg-opacity-50' : 'invisible bg-opacity-0'" @click.self="emits('close')">
    <div class="bg-[#2B2633] w-full transition-all duration-300 transform flex flex-col items-center justify-center"
      :class="isShow ? 'visible translate-y-0' : 'invisible -translate-y-full'">
      <div class="flex items-center justify-between w-full px-4 py-3 border-b border-white border-opacity-10">
        <div class="w-3" />
        <p>圖表選項</p>
        <img @click="emits('close')" class="w-3" src="@/assets/icons/close.svg" />
      </div>
      <div class="w-full px-6 py-2 mt-2">
        <p class="text-xs mb-3">主圖指標</p>
        <div class="flex items-center flex-wrap justify-between gap-3">
          <div v-for="opt of mainOptions" :key="opt" class="border text-xs text-center leading-8 rounded-lg"
            style="width: 5.5rem" :class="
              activeMainOption === opt
                ? 'border-main-700 bg-main-400'
                : 'bg-[#1C1316] border-[#1C1316]'
            " @click="emits('setMain', opt)">
            {{ opt }}
          </div>
        </div>
      </div>
      <div class="w-full px-6 py-2 mt-2">
        <p class="text-xs mb-3">副圖指標</p>
        <div class="flex items-center flex-wrap justify-between gap-3">
          <div v-for="opt of secondOptions" :key="opt" class="border text-xs text-center leading-8 rounded-lg"
            style="width: 5.5rem" :class="
              activeSecondOptions === opt
                ? 'border-main-700 bg-main-400'
                : 'bg-[#1C1316] border-[#1C1316]'
            " @click="emits('setSecond', opt)">
            {{ opt }}
          </div>
        </div>
      </div>
      <div class="flex items-center gap-6 pt-4 pb-6 w-full px-6">
        <div class="bg-[#413543] rounded-lg text-center flex-1 leading-10 text-sm" @click="resetOption">
          重置
        </div>
        <div class="bg-[#24B6E4] rounded-lg text-center flex-1 leading-10 text-sm" @click="setChart">
          確認
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'

const props = defineProps(['isShow', 'activeMainOption', 'activeSecondOptions'])
const emits = defineEmits([
  'close',
  'resetChart',
  'setChart',
  'setMain',
  'setSecond',
])
const mainOptions = ref(['MA', 'EMA', 'SAR'])
const secondOptions = ref(['VOL', 'MACD', 'KDJ'])
const resetOption = () => {
  emits('resetChart')
}
const setChart = () => {
  emits('setChart')
  emits('close')
}
</script>

<style></style>
