<template>
  <BackNav :title="currentMarket.title" bgColor="#202126" :scale="calculateScale(currentMarket.open, currentMarket.value)"
    :backShow="true">
  </BackNav>
  <div class="px-4 py-3 flex items-center justify-between">
    <div>
      <p class="text-3xl mb-2" :class="
        calculateScale(currentMarket.open, currentMarket.value) >= 0
          ? 'text-[#00C1A5]'
          : 'text-[#E81F4F]'
      ">
        {{ +currentMarket.value }}
      </p>
      <p class="text-xs">≈0.54 USD</p>
    </div>
    <div class="text-right">
      <div class="flex items-center gap-8 text-xs leading-5">
        <p class="text-[#909093] w-20">24h最高</p>
        <p class="w-20">{{ +currentMarket.high }}</p>
      </div>
      <div class="flex items-center gap-8 text-xs leading-5">
        <p class="text-[#909093] w-20">24h最低</p>
        <p class="w-20">{{ +currentMarket.low }}</p>
      </div>
      <div class="flex items-center gap-8 text-xs leading-5">
        <p class="text-[#909093] w-20">24h交易額</p>
        <p class="w-20">{{ +currentMarket.volume }}</p>
      </div>
    </div>
  </div>

  <div ref="chart" class="chart" style="background-color: #0f0f15">
    <Chart :currentMarket="currentMarket" />
  </div>
  <div class="flex items-center gap-4 py-2 px-4 text-sm fixed bottom-0 z-10 w-full bg-black">
    <div class="bg-[#00C1A5] flex-1 rounded-lg leading-10 text-center" @click="openBetModel(0)">
      買漲
    </div>
    <div class="bg-[#E81F4F] flex-1 rounded-lg leading-10 text-center" @click="openBetModel(1)">
      買跌
    </div>
  </div>
  <BetModel :isShow="isBetModelShow" :activeBetType="activeBetType" :currentMarket="currentMarket"
    :marketInfo="marketInfo" @setActiveBetType="activeBetType = $event" @close="isBetModelShow = false" />
</template>

<script setup>
import { ref } from '@vue/reactivity'
import { computed, onMounted } from 'vue'
import BackNav from '../components/BackNav.vue'
import Chart from '../components/Trade/Chart.vue'
import BetModel from '../components/Trade/BetModel.vue'
import { get_market_info } from '@/api/market'
import {
  addSignSymbolAndPercentage,
  calculateScale,
} from '@/utils/transfer-numbers'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
const store = useStore()
const route = useRoute()
const router = useRouter()
const chart = ref(null)
const isBetModelShow = ref(false)
const activeBetType = ref(0)
const marketInfo = ref({})
onMounted(async () => {
  const res = await get_market_info({
    market_id: route.params.id,
  })
  if (!res.error) {
    marketInfo.value = res
  }
})
const openBetModel = (betType) => {
  if (!store.state.isLogin) {
    router.push('/sign')
  } else {
    activeBetType.value = betType
    isBetModelShow.value = true
  }
}
const marketPrice = computed(() => store.state.marketPrice)
const currentMarket = computed(() => {
  return marketPrice.value.find((m) => +m.id === +route.params.id) || {}
})
</script>

<style lang="scss" scoped>
.chart {
  background: #f00;
  height: calc(100vh - 190px);
}
</style>
