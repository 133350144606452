<template>
  <div class="fixed inset-0 bg-black flex justify-center items-end transition-all duration-200 z-10"
    :class="isShow ? 'visible bg-opacity-50' : 'invisible bg-opacity-0'" @click.self="emits('close')">
    <div class="bg-[#2B2633] w-full transition-all duration-300 transform flex flex-col items-center justify-center"
      :class="isShow ? 'visible translate-y-0' : 'invisible translate-y-full'">
      <div class="relative flex items-center justify-between w-full py-3 border-b border-white border-opacity-10 text-sm">
        <p class="flex-1 text-center" :class="{ 'text-[#00C1A5]': activeBetType === 0 }"
          @click="emits('setActiveBetType', 0)">
          买涨
        </p>
        <p class="flex-1 text-center" :class="{ 'text-[#E81F4F]': activeBetType === 1 }"
          @click="emits('setActiveBetType', 1)">
          买跌
        </p>
        <div
          class="absolute bottom-0 transform translate-y-1/2 w-1/2 flex items-end justify-center transition-all duration-200"
          style="height: 2px" :style="`left:${50 * activeBetType}%`">
          <div class="w-8 h-full rounded transition-all duration-200"
            :class="activeBetType === 0 ? 'bg-[#00C1A5]' : 'bg-[#E81F4F]'" />
        </div>
      </div>
      <div class="w-full px-6 py-2 mt-2 flex items-center">
        <div class="flex-1 text-xs">
          <p class="mb-1">下注金额</p>
          <input v-model="betPrice" class="bg-[#1C1316] rounded-lg h-6 px-3 text-center border border-transparent"
            :class="activeBetType === 0 ? 'hover:border-[#00C1A5]' : 'hover:border-[#E81F4F]'"
            oninput="value=value.replace(/[^\d]/g,'')" type="number" />
        </div>
        <div class="flex-1 text-xs">
          <p class="mb-1">下注秒数</p>
          <select v-model="activeBetTime" placeholder="请选择时间"
            :class="activeBetType === 0 ? 'hover:border-[#00C1A5]' : 'hover:border-[#E81F4F]'"
            class="w-full bg-[#1C1316]  rounded-lg py-1 px-4  border border-transparent" size="small">
            <option v-for="item in betTime" :key="item.value" :label="`${item.text} 赔率${item.point_rate}%`"
              :value="item.betType" />
          </select>
        </div>
      </div>
      <div class="flex items-center gap-2 px-6 w-full">
        <div v-for="item of amountList" :key="item" class="text-xs px-2 leading-6 rounded-md flex-1 text-center border"
          :class="
            betPrice === item ? betOptionsClass : 'bg-[#1C1316] border-[#1C1316]'
          " @click="betPrice = item">
          {{ item }}
        </div>
      </div>
      <div class="flex items-center justify-between text-sm w-full px-6 mt-3">
        <p>余额：¥ {{ +userInfo.last_point }}</p>
        <p>手续费：{{ mainInfo.binary_fee_rate }}</p>
      </div>

      <div class="gap-4 pt-4 pb-6 w-full px-6">
        <div class="rounded-lg text-center flex-1 leading-10 text-sm transition-all duration-200"
          :class="activeBetType ? 'bg-[#E81F4F]' : 'bg-[#00C1A5]'" @click="submit">
          下单
        </div>
        <p class="mt-3 text-xs text-main-600 text-center" @click="emits('close')">
          取消
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { get_market_info } from '@/api/market'
import { useRoute } from 'vue-router'
import { showFailToast } from 'vant';
const route = useRoute()
const store = useStore()
const props = defineProps(['isShow', 'activeBetType', 'currentMarket'])
const emits = defineEmits(['close', 'setActiveBetType'])
const marketInfo = ref({})
const activeBetTime = ref(1)
const betPrice = ref(null)
const amountList = ref([100, 200, 500, 1000, 10000])
onMounted(async () => {
  const res = await get_market_info({
    market_id: route.params.id,
  })
  if (!res.error) {
    marketInfo.value = res
  }
})
const betTime = computed(
  () => marketInfo.value?.bet_info?.bets.filter((e) => e.active) || []
)
const submit = async () => {
  showFailToast('没有投注权限')
  // const res = await set_bet_order({
  //   market_binary_id: props.currentMarket.id,
  //   point: betPrice.value,
  //   direction_type: props.activeBetType + 1,
  //   bet_type: activeBetTime.value,
  // })
  // if (!res.error) {
  //   ElMessageBox.alert('下单成功', '提示')
  //   emits('close')
  // }
}
const betOptionsClass = computed(() => {
  if (props.activeBetType === 1) {
    return 'border-[#E81F4F] bg-[#3E212C]'
  } else {
    return 'border-[#00C1A5] bg-[#1B3939]'
  }
})
const userInfo = computed(() => store.state.userInfo)
const mainInfo = computed(() => store.state.mainInfo)
</script>

<style></style>
