<template>
  <div class="relative flex items-center justify-center px-4 py-3" :style="`background-color: ${bgColor}`">
    <img v-if="backShow" class="w-4 absolute" style="left: 1rem" @click="router.go(-1)" src="@/assets/icons/back.svg" />
    <div class="flex items-center gap-2" :class="{ 'transform translate-x-5': scale }">
      <p>{{ title }}</p>
      <p v-if="scale" class="py-1 px-2 text-center rounded-lg text-xs transform scale-75 origin-left" :class="[
        scale >= 0
          ? 'text-[#00C1A5] bg-[#1B3939]'
          : 'text-[#E81F4F] bg-[#3E212C]',
      ]">
        {{ addSignSymbolAndPercentage(+scale) }}
      </p>
    </div>
    <div class="absolute" style="right: 1rem">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { addSignSymbolAndPercentage } from '@/utils/transfer-numbers'
const props = defineProps(['title', 'bgColor', 'backShow', 'scale'])
const router = useRouter()
</script>

<style></style>
