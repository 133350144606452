<template>
  <div class="flex items-center justify-between px-4 text-xs py-2" style="background: #0f0f15">
    <p class="text-white">時間</p>
    <p v-for="item of timeOptionsList" :key="item.value" class="text-white transition-all duration-150"
      :class="{ 'text-opacity-50': activeTime !== item.value }" @click="activeTime = item.value">
      {{ item.label }}
    </p>
    <div>
      <img v-if="chartType === 1" class="h-4" @click="chartType = 0" src="@/assets/icons/trade/candle.svg" />
      <img v-else class="h-4" @click="chartType = 1" src="@/assets/icons/trade/area.svg" />
    </div>
    <img @click="isChartHandlerShow = true" class="h-4" src="@/assets/icons/trade/setting.svg" />
  </div>
  <div class="relative" style="height: calc(100% - 35px)">
    <div id="chart" class="w-full h-full" />
    <div v-show="loading" class="loading inset-0">
      <div class="loader loader1"></div>
      <div class="loader loader2"></div>
    </div>
  </div>
  <ChartHandler :isShow="isChartHandlerShow" :activeMainOption="activeMainOption"
    :activeSecondOptions="activeSecondOptions" @close="isChartHandlerShow = false" @resetChart="resetChart"
    @setChart="setChart" @setMain="activeMainOption = $event" @setSecond="activeSecondOptions = $event" />
</template>

<script setup>
import klinecharts from 'klinecharts'
import { onMounted, ref, watch } from 'vue'
import chartOptions from './chartOptions'
import ChartHandler from './ChartHandler.vue'
import { useStore } from 'vuex'
const props = defineProps(['currentMarket'])
const store = useStore()
let timer
let socket
let chart
let wsData = []
const timeOptionsList = ref([
  {
    label: '1分',
    value: 'min1',
  },
  {
    label: '5分',
    value: 'min5',
  },
  {
    label: '15分',
    value: 'min15',
  },
  {
    label: '1時',
    value: 'hour1',
  },
  {
    label: '1日',
    value: 'day1',
  },
])
const activeTime = ref('min1')
const chartType = ref(0)
const activeMainOption = ref(null)
const activeSecondOptions = ref(null)
const mainPaneId = ref(null)
const paneId = ref(null)
const isChartHandlerShow = ref(false)
const loading = ref(true)
const candleType = ['candle_solid', 'area']
const option = {
  url: store.state.mainInfo.ws
    ? store.state.mainInfo.ws
    : 'wss://www.kline-center.app/socket',
}
onMounted(() => {
  chartInit()
  startWs()
})
const chartInit = () => {
  const domId = 'chart'
  chart = klinecharts.init(`${domId}`)
  chart.setStyles(chartOptions)
  chart.setStyles('dark')
}
const setChartType = (type) => {
  chart.setStyles({
    candle: {
      type,
    },
  })
}
const resetChart = () => {
  activeMainOption.value = null
  activeSecondOptions.value = null
  chart.removeIndicator(mainPaneId.value)
  chart.removeIndicator(paneId.value)
}
const setChart = () => {
  if (activeMainOption.value) {
    setMainIndicator(activeMainOption.value)
  }
  if (activeSecondOptions.value) {
    setSubIndicator(activeSecondOptions.value)
  }
}
const setMainIndicator = (name) => {
  mainPaneId.value = chart.createIndicator(name, false, { id: 'candle_pane' })
}
const setSubIndicator = (name) => {
  paneId.value = chart.createIndicator(name, false, { id: paneId.value })
}
const startWs = () => {
  socket = new WebSocket(option.url)
  socket.onopen = function (evt) {
    socket.send(
      JSON.stringify({
        event: 'kline',
        data: {
          interval: activeTime.value,
          symbol: props.currentMarket.symbol,
        },
      })
    )
    heart()
  }
  socket.onmessage = function (event) {
    const data = JSON.parse(event.data)
    for (const evt of data.data) {
      evt.open = +evt.open_price
      evt.close = +evt.close_price
      evt.high = +evt.high_price
      evt.low = +evt.low_price
      evt.volume = +evt.volume
      evt.timestamp = evt.time * 1000
      delete evt.open_price
      delete evt.close_price
      delete evt.high_price
      delete evt.low_price
      delete evt.time
      updateData(data)
    }
  }
  socket.onclose = function (event) {
    console.log('中斷連線', event)
    clearInterval(timer)
  }
}
const heart = () => {
  timer = setInterval(() => {
    socket.send(
      JSON.stringify({
        action: 'heart',
      })
    )
  }, 1000)
}
const updateData = (data) => {
  if (!wsData.length) {
    chart.applyNewData(data.data)
  } else {
    if (
      data.data[data.data.length - 1].timestamp ===
      wsData[wsData.length - 1]?.timestamp
    ) {
      data.data[data.data.length - 1].close = +props.currentMarket?.value
    }
    chart.updateData(data.data[data.data.length - 1])
  }
  loading.value = false
  wsData = data.data
}
watch(
  () => activeTime.value,
  (newVal) => {
    loading.value = true
    socket.close()
    klinecharts.dispose(chart)
    wsData = []
    chartInit()
    startWs()
    setChart()
  }
)
watch(
  () => chartType.value,
  (newVal) => {
    setChartType(candleType[newVal])
  },
  { deep: true }
)
</script>

<style lang="scss" scoped>
.loading {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 999px;
  border-style: solid;
  border-width: 3px;
}

.loader1 {
  width: 35px;
  height: 35px;
  transform: translate(-50%, -50%);
  border-color: #1cf8a1 transparent #1cf8a1 transparent;
  animation: loading 1s infinite linear;
}

.loader2 {
  width: 65px;
  height: 65px;
  transform: translate(-50%, -50%);
  border-color: transparent #1cf8a1 transparent #1cf8a1;
  animation: loading 0.8s infinite linear;
}

@keyframes loading {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
